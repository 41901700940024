import { useLobbyGameApi } from '@/api/lobby-game'
import { useAppStore } from '@/store/app'

export const useJackpot = () => {
  const { $axios } = useNuxtApp()
  const { setJackpot, setSumJackpot } = useAppStore()
  const { JACKPOT_EVENT, JACKPOT_SLOT } = useLobbyGameApi()
  let eventSource: EventSource

  const startJackpotEvent = () => {
    if (EventSource) {
      eventSource = new EventSource(JACKPOT_EVENT)
      eventSource.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data)
          setJackpot(data.jackpotData)
          setSumJackpot(data.sumjackpot)
        } catch (error) {
          console.error(error)
        }
      }
    } else {
      console.error("Your browser doesn't support SSE")
    }
  }

  /*
   * Purpose: Only use when page needs to Server-Side-Rendering
   */
  const fetchJackpot = async () => {
    try {
      const { data: response } = await $axios.get(JACKPOT_SLOT)
      if (response && response.status === 'OK') {
        setJackpot(response.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return { startJackpotEvent, fetchJackpot }
}
